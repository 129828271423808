
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: { unit: { type: Number, required: true } },
  setup(props) {
    const store = useStore();
    const notiz = ref('');

    onMounted(() => {
      store.dispatch('fetchNotiz', props.unit).then((res) => {
        if (res && res != null) {
          notiz.value = res;
        }
      });
    });

    return {
      notiz,
    };
  },
});
