
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import IndexTables from '../../components/IndexTables.vue';
import _ContainerVue from './_Container.vue';
import GradingForm from './management/_GradingForm.vue';

export default defineComponent({
  components: {
    IndexTables,
    GradingForm,
  },
  setup() {
    const tab = ref<'data' | 'grading'>('data');
    const store = useStore();
    const router = useRouter();
    const name = ref('');

    const id = computed(() => parseInt(router.currentRoute.value.params.id.toString()));
    store.dispatch('fetchUserData', id.value).then((res: any) => {
      name.value = `${res.firstname} ${res.lastname}`;
    });

    const onTab = (val: 'data' | 'grading') => {
      tab.value = val;
    };

    const saving = ref(false);

    const onSave = (user: any) => {
      if (!saving.value) {
        saving.value = true;
        store.dispatch('updateWorker', { id: id.value, user }).then((res: any) => {
          store.dispatch('addNotification', { message: 'Mitarbeiter gespeichert' });
          saving.value = false;
          //router.push({ name: 'SkillsMitarbeiterList' });
        });
      }
    };

    return {
      id,
      name,
      tab,
      onTab,
      onSave,
    };
  },
});
