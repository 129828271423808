import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "index" }
const _hoisted_2 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Headline = _resolveComponent("Headline")!
  const _component_IndexTables = _resolveComponent("IndexTables")!
  const _component_GradingForm = _resolveComponent("GradingForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Headline, {
      h1: "Skillübersicht",
      h2: `von ${_ctx.name}`
    }, null, 8, ["h2"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTab('data'))),
        class: _normalizeClass({ active: _ctx.tab == 'data' })
      }, "Skillübersicht", 2),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onTab('grading'))),
        class: _normalizeClass({ active: _ctx.tab == 'grading' })
      }, "Mitarbeiter:innengespräch", 2)
    ]),
    (_ctx.tab == 'data')
      ? (_openBlock(), _createBlock(_component_IndexTables, {
          key: 0,
          type: "personal",
          unit: _ctx.id,
          name: _ctx.name,
          simple: ""
        }, null, 8, ["unit", "name"]))
      : _createCommentVNode("", true),
    (_ctx.tab == 'grading')
      ? (_openBlock(), _createBlock(_component_GradingForm, {
          key: 1,
          onSave: _ctx.onSave,
          edit: "",
          userid: _ctx.id
        }, null, 8, ["onSave", "userid"]))
      : _createCommentVNode("", true)
  ]))
}