
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';

import CompareDialogue from './CompareDialogue.vue';

import EntwicklungsPlan from './EntwicklungsPlan.vue';
import FileList from './FileList.vue';
import Zusammenarbeit from './custom/Zusammenarbeit.vue';

export default defineComponent({
  props: {
    type: { type: String, default: 'personal' },
    unit: { type: Number },
    name: { type: String, default: '' },
    simple: { type: Boolean, default: false },
  },
  components: {
    CompareDialogue,

    EntwicklungsPlan,
    FileList,
    Zusammenarbeit,
  },
  setup(props) {
    const store = useStore();
    const compareOpen = ref(false);
    const overviewHeader = ref('');
    const tableHeader = ref('');
    const files = ref<Array<number>>([]);

    const compareClick = () => {
      compareOpen.value = true;
    };

    const compareClose = () => {
      compareOpen.value = false;
    };

    switch (props.type) {
      case 'unit':
        store.dispatch('fetchSkillsForUnit', props.unit);
        overviewHeader.value = 'Unit';
        tableHeader.value = 'Mitarbeiter:innen';
        //store.dispatch('fetchUnitGrades', props.unit);
        break;
      case 'company':
        // store.dispatch('fetchOverviewGrades');
        store.dispatch('fetchSkills');
        overviewHeader.value = 'Company';
        tableHeader.value = 'Abteilungen';
        // store.dispatch('fetchCompanyGrades');
        break;
      case 'personal':
        store.dispatch('fetchUserData', props.unit).then((res: any) => {
          files.value = JSON.parse(res.files);
          store.dispatch('fetchSkillsForUnit', res.unit_id);
        });
        break;
    }

    const social = computed(() => store.getters.getSocialSkills || false);
    const working = computed(() => store.getters.getWorkingSkills || false);
    const personal = computed(() => store.getters.getPersonalSkills || false);

    return {
      working,
      social,
      personal,
      overviewHeader,
      tableHeader,
      compareOpen,
      compareClick,
      compareClose,
      files,
    };
  },
});
